/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Version: 2.0
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import '../../@core/scss/base/bootstrap-extended/include'; // Bootstrap includes

@mixin material-shadow($level: 1) {	
	@if $level ==1 {
		box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
	}

	@if $level ==2 {
		box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23);
	}

	@if $level ==3 {
		box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
	}

	@if $level ==4 {
		box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
	}

	@if $level ==5 {
		box-shadow: 0 19px 38px rgba(0, 0, 0, 0.30), 0 15px 12px rgba(0, 0, 0, 0.22);
	}
}

// Mixin by Daniel Ott
@function makelongshadow($color) {
	$val: 0px 0px $color;
	$shadowlength: 5;

	@for $i from 1 through $shadowlength {
		$val: #{$val},
		#{$i}px #{$i}px #{$color};
	}

	@return $val;
}

@mixin longshadow($color) {
	text-shadow: makelongshadow($color);
}


#social-share {
	position: fixed;
	bottom: 9vh;
	right: 20px;
	z-index: 20;

	%btn {
		position: relative;
		cursor: pointer;
		border-radius: 50%;
		color: white;
		line-height: 1;
		outline: 0;
		border: 0;
		margin-top: 15px;
		@include material-shadow(1);

		&:hover {
			@include material-shadow(2);
		}
	}

	ul.social-itens {
		list-style: none;
		text-decoration: none;
		text-align: center;
		position: relative;
		display: block;
		padding-inline-start: 0px;

		/* display: none; */
		.btn-share {
			@extend %btn;
			width: 50px;
			height: 50px;

			&.social-item-1 {
				background-color: #3b5998;

				i {
					@include longshadow(darken(#3b5998, 5%));
				}
			}

			&.social-item-2 {
				background-color: #FF0000;

				i {
					@include longshadow(darken(#FF0000, 5%));
				}
			}

			&.social-item-3 {
				background-color: #00acee;

				i {
					@include longshadow(darken(#00acee, 5%));
				}
			}

			&.social-item-4 {
				background-color: #0072b1;

				i {
					@include longshadow(darken(#0072b1, 5%));
				}
			}

			&.social-item-5 {
				background-color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
				background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
				background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
				background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);

				i {
					@include longshadow(darken(#f09433, 5%));
				}
			}

			/* transform: none; */
		}

		&.open .btn-share {
			transform: scale(1);
			opacity: 1;
			/* transform: translate(0px, -15px); */
		}

		li {
			position: relative;

			.btn-share {
				transform: scale(0);
				opacity: 0;
			}

			.btn-share-text {
				/* opacity: 0; */
				position: absolute;
				bottom: 15px;
				right: 100%;
				margin-right: 10px;
				min-width: 75px;
				border-radius: 2px;
				padding: 3px 4px;
				text-align: center;
				font-weight: 300;
				background: rgba(20, 20, 20, 0.7);
				color: #fff;
				display: block;
				opacity: 0;
				/* transition: all 500ms ease-in-out  0.5s; */
			}

			&:hover .btn-share-text {
				opacity: 1;
			}
		}
	}

	div.social-open-menu {
		.btn-share {
			@extend %btn;
			width: 65px;
			height: 65px;
			background-color: #11111190;

			i {
				@include longshadow(darken(#11111190, 5%));
			}
		}
	}

	/*transitions with relative delay*/
	$menu-items: 4;
	$i: 1;

	@while $i <=$menu-items {

		.social-itens.open .social-item-#{$i},
		.social-itens.open .social-item-#{$i} .btn-share-text {
			transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550) $i*0.1s;
		}

		.social-itens.hidden .social-item-#{$i},
		.social-itens.open .social-item-#{$i} .btn-share-text {
			transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550) ($menu-items - $i + 1)*0.1s;
		}

		$i: $i + 1;
	}
}

@include media-breakpoint-down(md) {
	#social-share {
		position: fixed;
		bottom: 13vh;
		right: 20px;
		z-index: 20;
	
		%btn {
			position: relative;
			cursor: pointer;
			border-radius: 50%;
			color: white;
			line-height: 1;
			outline: 0;
			border: 0;
			margin-top: 15px;
			@include material-shadow(1);
	
			&:hover {
				@include material-shadow(2);
			}
		}
	
		ul.social-itens {
			list-style: none;
			text-decoration: none;
			text-align: center;
			position: relative;
			display: block;
			padding-inline-start: 0px;
	
			/* display: none; */
			.btn-share {
				@extend %btn;
				width: 40px;
				height: 40px;
	
				&.social-item-1 {
					background-color: #3b5998;
	
					i {
						@include longshadow(darken(#3b5998, 5%));
					}
				}
	
				&.social-item-2 {
					background-color: #FF0000;
	
					i {
						@include longshadow(darken(#FF0000, 5%));
					}
				}
	
				&.social-item-3 {
					background-color: #00acee;
	
					i {
						@include longshadow(darken(#00acee, 5%));
					}
				}
	
				&.social-item-4 {
					background-color: #0072b1;
	
					i {
						@include longshadow(darken(#0072b1, 5%));
					}
				}
	
				&.social-item-5 {
					background-color: radial-gradient(circle at 30% 107%, #fdf497 0%, #fdf497 5%, #fd5949 45%, #d6249f 60%, #285AEB 90%);
					background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
					background: -webkit-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
					background: linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%);
	
					i {
						@include longshadow(darken(#f09433, 5%));
					}
				}
	
				/* transform: none; */
			}
	
			&.open .btn-share {
				transform: scale(1);
				opacity: 1;
				/* transform: translate(0px, -15px); */
			}
	
			li {
				position: relative;
	
				.btn-share {
					transform: scale(0);
					opacity: 0;
				}
	
				.btn-share-text {
					/* opacity: 0; */
					position: absolute;
					bottom: 15px;
					right: 100%;
					margin-right: 10px;
					min-width: 75px;
					border-radius: 2px;
					padding: 3px 4px;
					text-align: center;
					font-weight: 300;
					background: rgba(20, 20, 20, 0.7);
					color: #fff;
					display: block;
					opacity: 0;
					/* transition: all 500ms ease-in-out  0.5s; */
				}
	
				&:hover .btn-share-text {
					opacity: 1;
				}
			}
		}
	
		div.social-open-menu {
			.btn-share {
				@extend %btn;
				width: 50px;
				height: 50px;
				background-color: #11111190;
	
				i {
					@include longshadow(darken(#11111190, 5%));
				}
			}
		}
	
		/*transitions with relative delay*/
		$menu-items: 4;
		$i: 1;
	
		@while $i <=$menu-items {
	
			.social-itens.open .social-item-#{$i},
			.social-itens.open .social-item-#{$i} .btn-share-text {
				transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550) $i*0.1s;
			}
	
			.social-itens.hidden .social-item-#{$i},
			.social-itens.open .social-item-#{$i} .btn-share-text {
				transition: all 500ms cubic-bezier(0.680, -0.550, 0.265, 1.550) ($menu-items - $i + 1)*0.1s;
			}
	
			$i: $i + 1;
		}
	}
  }