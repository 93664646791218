.ql-editor {
  min-height: 300px;
}

.spinnerModal > div {
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: none !important;
  background-color: transparent !important;
  border: none !important;
}

.offcanvas {
  opacity: 0.95;
}

.tour-loader {
  height: 95vh;
  width: 100%;
  display: grid;
  place-items: center;
}

.menu-button {
  transition: 0.2s;
}

.menu-button:hover {
  background-color: #434343;
  border-color: #616060;
  border-radius: 5px;
}

.react-dataTable {
  min-height: 350px !important;
}

.map-container {
  width: 100%;
  height: 80vh;
}

.user-map-container {
  width: 100%;
  height: 300px;
}

.locationWarn {
  width: 100%;
  height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationModal {
  position: absolute !important;
  bottom: 6vh !important;
  width: 40%;
}

@media only screen and (max-width: 800px) {
  .user-map-container {
    width: 100%;
    height: 200px;
  }
  .locationModal {
    bottom: 12vh !important;
  }
  .modalHeading {
    font-size: 18px;
  }
}

.customMarker {
  border: 3px solid red !important;
}

#google_translate_element select {
  margin-left: 15px;
  background: #e2eaf298;
  color: #000;
  border: none;
  border-radius: 3px;
  padding: 6px 8px;
  outline: none;
}

/*google translate link | logo */
.goog-logo-link,
.goog-te-gadget span,
div#goog-gt- {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
  font-size: 0;
}

/* google translate banner-frame */

.goog-te-banner-frame {
  display: none !important;
}

#goog-gt-tt,
.goog-te-balloon-frame {
  display: none !important;
}

.goog-text-highlight {
  background: none !important;
  box-shadow: none !important;
}

.goog-te-banner-frame {
  display: none !important;
}

.goog-logo-link {
  display: none !important;
}

.goog-te-gadget {
  color: transparent !important;
}

.VIpgJd-ZVi9od-ORHb-OEVmcd {
  display: none !important;
}

body {
  top: 0 !important;
}

.icon_btn {
  background-color: transparent !important;
  border-width: 0;
  box-shadow: none !important;
  flex-wrap: wrap !important;
}

.sc-kgTSHT {
  background: transparent !important;
}

.fs-16 {
  font-size: 1rem !important;
}

.fs-18 {
  font-size: 18px !important;
}

.fs-20 {
  font-size: 20px !important;
}

.youtube-video-embed {
  width: 100%;
}

.select-width {
  width: 300px !important;
}
.login-logo img {
  /* height: 100px; */
  max-width: 200px;
  margin-top: -40px;
  margin-left: -30px;
  /* top: -10px; */
}
